import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import OrderingInformation from "../components/OrderingInformation"
import Seo from "../components/Seo"
import { BASE_BREADCRUMBS } from '../constants'

const PatentsPage = ({ data }) => {
  let tableData = {
    header: [
      { name: "Name" },
      { name: "US Registration Number" }
    ],
    rowData: []
  }
  if (data) {
    data.allTrademarks.nodes?.forEach(trademark => {
      let ind = tableData.rowData.findIndex(row => row.cellData[0] === trademark.name)
      if (ind >= 0) {
        tableData.rowData[ind].cellData[1] += `\n${trademark.us_registration_number}`
      } else {
        tableData.rowData.push({
          cellData: [
            trademark.name,
            trademark.us_registration_number
          ]
        })
      }
    })
  }
  return (
    <Layout
      pageTitle="Trademarks"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/trademarks", name: "Trademarks" }]}
    >
      <Seo
        title="Trademarks | Typenex Medical"
        description="Learn about Typenex Trademarks."
      />
      <OrderingInformation noTitle={true} data={[tableData]} />
    </Layout>
  )
}

export default PatentsPage

export const query = graphql`
  query {
    allTrademarks {
      nodes {
        name
        us_registration_number
      }
    }
  }
`
